'use client';

import { Dispatch, createContext, useContext, useReducer } from 'react';
import { produce } from 'immer';
import { AllProviderTypes } from '../config/providerTypes';

export type PageHistoryEntry = {
	pathname: string;
	providerType: AllProviderTypes | undefined;
	partners: string[] | undefined;
};

export type PageHistory = Array<PageHistoryEntry>;

const PageHistoryContext = createContext<
	[PageHistory, Dispatch<PageHistoryEntry>] | undefined
>(undefined);

const reducer = produce((state: PageHistory, action: PageHistoryEntry) => {
	if (state.at(-1)?.pathname === action.pathname) {
		return;
	}
	state.push(action);
});

export const PageHistoryProvider = ({
	children,
}: {
	children?: React.ReactNode;
}) => {
	const reducerTuple = useReducer(reducer, []);

	return (
		<PageHistoryContext.Provider value={reducerTuple}>
			{children}
		</PageHistoryContext.Provider>
	);
};

export const usePageHistory = () => {
	const context = useContext(PageHistoryContext);
	if (context == null) {
		throw new Error('usePageHistory must be used within a PageHistoryProvider');
	}

	return context;
};

export const useVisitedPartners = () => {
	const [pageHistory] = usePageHistory();
	return new Set(pageHistory.flatMap((entry) => entry.partners ?? []));
};

const menuProviderTypes: Array<AllProviderTypes> = [
	'menu',
	'themaMenu',
	'browse',
	'search',
];
export const useLastPagePathnameBeforeMenu = () => {
	const [pageHistory] = usePageHistory();
	const lastPage = pageHistory.findLast(({ providerType }) => {
		if (providerType == null) return true;

		return !menuProviderTypes.includes(providerType);
	});

	return lastPage?.pathname;
};

export const useReferrer = () => {
	const [pageHistory] = usePageHistory();
	return pageHistory.at(-1)?.pathname;
};
