import { AnyAction } from 'redux';
import { produce } from 'immer';

import { arrify } from '@dop/shared/helpers/functional';

import { hasWindow } from '../helpers/windowDocument';
import { HrefKind } from '@/globals/routing/link/functions/getLinkInfo';
import {
	LOCATION_CHANGE,
	LOCATION_CHANGE_IN_NEW_WINDOW,
} from '@/globals/routing/location/locationChangeActions';
import { ReduxRootState } from '@/globals/dataProviders/store';

type Event = {
	category?: string;
	action?: string;
	label?: string;
	value?: number;
};

export type EventData = {
	event: string;
	events: Event;
	page?: {
		urlPath?: string;
		[key: string]: unknown;
	};
	user?: {
		[key: string]: unknown;
	};
};

type GetEventDataFn = (action: AnyAction, state: ReduxRootState) => EventData;
type GetPromisedEventDataFn = (
	action: AnyAction,
	state: ReduxRootState
) => Promise<EventData>;

type PushEventFn = (eventData: EventData) => void;

export const pushSingleEvent = (eventData: EventData) => {
	if (eventData != null) {
		const urlPath = window.location.pathname;
		const updatedEvent = produce(eventData, (draft) => {
			// set events props to either an actual value or undefined
			draft.events = draft.events ?? {};
			draft.events.category = draft.events.category ?? undefined;
			draft.events.action = draft.events.action ?? undefined;
			draft.events.label = draft.events.label ?? undefined;
			// keep old url path if possible
			draft.page = draft.page ?? {};
			draft.page.urlPath = draft.page.urlPath ?? urlPath;
		});

		window.dataLayer.push(updatedEvent);
	}
};

export const pushEvent: PushEventFn = (eventData) => {
	if (hasWindow() && window.dataLayer != null) {
		const eventDataList = arrify(eventData);

		for (const eventDataEntry of eventDataList) {
			pushSingleEvent(eventDataEntry);
		}
	}
};

export const asyncPushEvent = (promiseWithEventData: Promise<EventData>) => {
	// Test if promise exists (on ssr it will return undefined)
	promiseWithEventData?.then((eventData) => pushEvent(eventData));
};

export const whenOfType =
	(type: string) =>
	(fn: (action: AnyAction, state: ReduxRootState) => void) =>
	(action: AnyAction, state: ReduxRootState) =>
		String(type) === action.type ? fn(action, state) : null;

export const whenOfAnyType =
	(types: Array<string>) =>
	(fn: (action: AnyAction, state: ReduxRootState) => void) =>
	(action: AnyAction, state: ReduxRootState) =>
		types.includes(action.type) ? fn(action, state) : null;

export const EXISTS = Symbol('exists');

export const whenContains =
	(eqObj: Record<string, unknown>) =>
	(fn: (action: AnyAction, state: ReduxRootState) => void) =>
	(action: AnyAction, state: ReduxRootState) =>
		Object.keys(eqObj).every((key) =>
			eqObj[key] === EXISTS ? key in action : eqObj[key] === action[key]
		)
			? fn(action, state)
			: null;

export const pushWhenOfType = (type: string) => (fn: GetEventDataFn) =>
	whenOfType(type)((action, state) => {
		pushEvent(fn(action, state));
	});

export const asyncPushWhenOfType =
	(type: string) => (fn: GetPromisedEventDataFn) =>
		whenOfType(type)((action, state) => {
			asyncPushEvent(fn(action, state));
		});

export const pushWhenOfAnyType =
	(types: Array<string>) => (fn: GetEventDataFn) =>
		whenOfAnyType(types)((action, state) => {
			pushEvent(fn(action, state));
		});

export const pushWhenContains =
	(obj: Record<string, unknown>) => (fn: GetEventDataFn) =>
		whenContains(obj)((action, state) => {
			pushEvent(fn(action, state));
		});

export const pushWhenOfAnyHrefKind =
	(hrefKinds: Array<HrefKind>) =>
	(fn: GetEventDataFn) =>
	(action: { type: string; hrefKind?: HrefKind }, state: ReduxRootState) => {
		if (
			[LOCATION_CHANGE, LOCATION_CHANGE_IN_NEW_WINDOW].includes(action.type) &&
			action.hrefKind != null &&
			hrefKinds.includes(action.hrefKind)
		) {
			pushEvent(fn(action, state));
		}
	};

export const pushWhenOfHrefKind = (hrefKind: HrefKind) =>
	pushWhenOfAnyHrefKind([hrefKind]);
