const baseUrl = 'ondernemersplein.kvk.nl';
const apiUrl = `https://api.${baseUrl}/`;

/** @type {import('moduleAlias/environmentConfig').EnvironmentConfig} */
const environmentConfig = {
	env: 'productie', // used to determine if production/non-production GTM should be loaded
	recaptchaKey: '6Leh2MQlAAAAAESaaP5CnSvmfEUv2BNNMLuBx3vI',
	localWebApiUrl: 'http://localhost:8000/',
	webApiUrl: apiUrl,
	webApiContentService: 'content/v1',
	webApiImagesService: 'images/v1',
	webApiFormService: 'forms/v1',
	port: 80,
	cacheEnabled: true,
	searchUrl: `https://zoeken.${baseUrl}/api/v3/search`,
	searchScOrganisationsUrl: `https://zoeken.${baseUrl}/api/v2/search/scorganisaties`,

	imageCDN: 'https://production.kvk-dop.bloomreach.cloud',
	hasCmsPreview: false,
	switchEnvURL: 'https://staging.ondernemersplein.kvk.nl',
	switchEnvLabel: 'Naar preview',
	previewRefreshURL: '', //no auto refresh on PRD

	originUrl: `https://${baseUrl}`,
	flagshipEnabled: true,
	flagshipApiUrl: 'https://decision-api.flagship.io/v1',
	flagshipEnviromentId: 'bnft3ccdg2jg2rif6urg', // 'bnft3ccdg2jg2rif6urg' in PROD,
	flagshipMode: 'normal', // 'normal' in PROD,
	serverUrlSeq: 'https://dop-seq.svc.prd.kvk.nl/',
	cspRules: {
		defaultSrc: [`*.${baseUrl}`, '*.ondernemersplein.nl', '*.op.nl'],
		connectSrc: [`*.${baseUrl}`],
		imgSrc: [`*.${baseUrl}`, `production.kvk-dop.bloomreach.cloud`],
		styleSrc: [`*.${baseUrl}`],
		frameSrc: [`*.${baseUrl}`, '*.ondernemersplein.nl'],
		scriptSrc: [`*.${baseUrl}`],
	},
};

module.exports = environmentConfig;
