'use client';

import React from 'react';
import { addEventListenerWithOptions } from '@dop/shared/helpers/dom';
import { getWindow, getDocument } from '@dop/shared/helpers/windowDocument';
import { unexisty } from '@dop/shared/helpers/functional';
import { getScrollTop } from '@dop/shared/helpers/scroll';
import { getWindowSize } from '@dop/shared/helpers/getWindowSize';
import { AnyAction } from 'redux';
import { usePathname } from 'next/navigation';
import { ReduxDispatch } from '../dataProviders/store';
import { useDOPDispatch } from '../dataProviders/reduxHooks';

export const SCROLL_TRIGGER_POINT_HIT = 'SCROLL_TRIGGER_POINT_HIT';

const scrollTriggerPointHit = (scrollPercentagesHit: Array<number>) => {
	return {
		type: SCROLL_TRIGGER_POINT_HIT,
		scrollPercentagesHit: scrollPercentagesHit || [],
	};
};

const fireScrollEvents = ({
	previousScrollPercentage,
	currentScrollPercentage,
	dispatch,
}: {
	previousScrollPercentage: number;
	currentScrollPercentage: number;
	dispatch: ReduxDispatch;
}) => {
	const scrollPercentagesHit: Array<number> = [];
	for (let percentage = 10; percentage <= 100; percentage += 10) {
		if (
			percentage > previousScrollPercentage &&
			percentage <= currentScrollPercentage
		) {
			scrollPercentagesHit.push(percentage);
		}
	}

	if (scrollPercentagesHit.length > 0) {
		dispatch(scrollTriggerPointHit(scrollPercentagesHit));
	}
};

const scrollEvent =
	({
		maxReachedScrollPercentage,
	}: {
		maxReachedScrollPercentage: { current: number };
	}) =>
	(dispatch: ReduxDispatch) => {
		const doc = getDocument();
		if (doc == null) return;

		const windowHeight = getWindowSize().height;
		const scrollTop = getScrollTop();

		// get height of the first <article> inside <main>,
		// or the main container (everything within the <main class="main"> tags),
		// or fallback to the document-height
		const mainContentEl = doc.querySelector('main');
		const articleEl = mainContentEl
			? mainContentEl.querySelector('article#article')
			: null;

		const contentHeight = articleEl
			? articleEl.clientHeight
			: mainContentEl
			? mainContentEl.clientHeight
			: doc.body.clientHeight;
		const contentTop = articleEl
			? scrollTop + articleEl.getBoundingClientRect().top
			: mainContentEl
			? scrollTop + mainContentEl.getBoundingClientRect().top
			: 0;

		const scrollableHeight = contentHeight - windowHeight;
		// calculate the relative window-portion that's scrolled compared to the content
		const windowFactor =
			contentHeight > windowHeight
				? (scrollTop - contentTop) / scrollableHeight
				: 0;

		// if the page content is visible without scrolling, return 0
		// we ignore the footer-height, since the scroll-event ignores that space
		const scrollPercentage =
			scrollableHeight > 0
				? ((scrollTop - contentTop + windowHeight * windowFactor) /
						contentHeight) *
				  100
				: 0;

		// fire event if scrollPercentage is higher than the last max
		if (scrollPercentage > maxReachedScrollPercentage.current) {
			fireScrollEvents({
				previousScrollPercentage: maxReachedScrollPercentage.current,
				currentScrollPercentage: scrollPercentage,
				dispatch,
			});
			// eslint-disable-next-line no-param-reassign
			maxReachedScrollPercentage.current = scrollPercentage;
		}
	};

export const useScrollLogging = () => {
	const dispatch = useDOPDispatch();

	const urlPath = usePathname();

	const maxReachedScrollPercentage = React.useRef(0);

	React.useEffect(() => {
		const win = getWindow();

		if (unexisty(win)) return undefined;

		// When urlPath changes, reset maxReachedScrollFraction
		maxReachedScrollPercentage.current = 0;

		let removeScrollEventListener = () => {};

		// Delay listening for scroll event to avoid timing issue
		// with scroll resteration after page transition
		const timer = setTimeout(() => {
			// dispatch right away in case the page has
			// already been scrolled on page load
			dispatch(
				scrollEvent({ maxReachedScrollPercentage }) as unknown as AnyAction
			);

			removeScrollEventListener = addEventListenerWithOptions({
				target: win,
				eventName: 'scroll',
				handler: () => {
					dispatch(
						scrollEvent({ maxReachedScrollPercentage }) as unknown as AnyAction
					);
				},
				options: {
					capture: false,
					passive: true,
				},
			});
		}, 200);

		return () => {
			clearTimeout(timer);
			removeScrollEventListener();
		};
	}, [urlPath, dispatch]);
};

export const UseScrollLogging = () => {
	useScrollLogging();
	return null;
};
